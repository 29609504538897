import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import config from '../../config'
import { navigate } from 'gatsby-link'
import ReactDOM from 'react-dom'

//import { oneDark } from '@codemirror/theme-one-dark';
//import CodeMirror from '@uiw/react-codemirror';
//import { javascript } from '@codemirror/lang-javascript';
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'
import renderHTML from 'react-render-html';


const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});


let htmlvalue = `
<div style="background-color: #23c495; text-align: center">
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/">
  <img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo-mail.png?v=1651011958996" width="300px" /></a></div>

<div style="background-color: #23c495; display: flex;align-items: center;  justify-content: space-between">
<div style="display: block; text-align: left;color:white;padding-left:10px">
  <h1 style="font-size:1.5em">Discover our offer</h1>
  <h2 style="border-radius:5px;border-bottom:15px solid #fcc515;font-size:3em;max-width:80%">Graphic design</h2>
</div>
<div><a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/"><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/top.png?v=1651011033614" width="100%" /></a></div>
</div>
<br>
<h3 style="font-size:2em;text-align: center;margin-top:30px">Main areas</h3>

<div style="display: flex; justify-content: center;">
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/logotypes/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l4.png?v=1651011237576" width="100%" /><br><p style="text-align:center">Company <br>logotypes</p></div></a>
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/website-design/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l3.png?v=1651011237576" width="100%" /><br><p style="text-align:center">Web<br> design</p></div></a>
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/vector-graphic/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l2.png?v=1651011237791" width="100%" /><br><p style="text-align:center">Vector <br>graphics</p></div></a>
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/siv-visual-identification/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l1.png?v=1651011237864" width="100%" /><br><p style="text-align:center">Visual <br>identity</p></div></a>
</div>
<br><br><br>
<hr style="border-width:5px;border-color: #23c495;width:70%" />
<br><br><br>
<div style="background-color: #23c495;color:white;padding-left:20px;display: flex;align-items: center; justify-content: space-between">
<div style="display: block; text-align: left">
  <h2 style="font-size:2em">Company logotypes</h2>
  <p>
  Unique logotypes for your brand! Refresh your image or give it to a new brand or product.
    <br><br><br>
    <a style="color:white;border-radius:15px;padding:10px 20px;border:1px solid white;" target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/logotypes/">read more</a>
  </p>
</div>
<div>
  <a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/logotypes/">
    <img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/logo.png?v=1651011033145" width="100%" /></a></div>
</div>
<br><br>
<div style="display: flex;align-items: center;  justify-content: space-between">
<div><a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/website-design/"><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/strony.png?v=1651011034178" width="100%" /></a></div>
<div style="display: block; color:#23c495;padding-right:20px; text-align: right">
  <h2 style="font-size:2em;">Website design</h2>
  <p>
  UI and UX design, modern websites and their graphic design.
    <br><br><br>
    <a style="color:#23c495;border-radius:15px;padding:10px 20px;border:1px solid #23c495;" target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/website-design/">read more</a>
  </p>
</div>
</div>

<div style="background-color: #23c495;color:white;padding-left:20px;display: flex;align-items: center;  justify-content: space-between">
<div style="display: block; text-align: left">
  <h2 style="font-size:2em;">Vector graphics</h2>
  <p>
  Designs in vector format - suitable for many printing solutions.
    <br><br><br>
    <a style="color:white;border-radius:15px;padding:10px 20px;border:1px solid white;" target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/vector-graphic/">read more</a>
  </p>
</div>
<div><a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/vector-graphic/"><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/grafwekt.png?v=1651011034023" width="100%" /></a></div>
</div>

<div style="display: flex;align-items: center;  justify-content: space-between">
<div><a target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/siv-visual-identification/"><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/identyfikacja.png?v=1651011034407" width="100%" /></a></div>
<div style="display: block;color:#23c495;padding-right:20px; text-align: right">
  <h2 style="font-size:2em;">Visual identification</h2>
  <p>
  Effective visual identification systems, gadgets, corporate prints, digital advertising.
    <br><br><br>
    <a style="color:#23c495;border-radius:15px;padding:10px 20px;border:1px solid #23c495;" target="_blank" rel="noopener noreferrer" href="https://artopen.co/offer/graphic-design/siv-visual-identification/">read more</a>
  </p>
</div>
</div>
<p style="text-align:center">
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/order/graphic-design/" style="color:white;background-color:#23C495;border-radius:15px;padding:10px 20px;font-size:1.5em">Price the service</a>
</p>


<br><br>
<hr style="border-width:5px;border-color: #23c495;width:70%" />
<br><br>
<h3 style="text-align: center;font-size:2.5em;">Check out our blog</h3>
<br><br>
<div
style="
  background-color: #23c495;
  display: flex;align-items: center;
  justify-content: space-between;
"
>
<div style="display: block; text-align: left;padding-left:20px;color:white">
  <h1 style="font-size:2em;">How to create a logo for a company or brand?</h1>
  <p>
  Useful tips, instructions and design principles for company logos and signs.
    <br><br><br>
    <a style="color:white;border-radius:15px;padding:10px 20px;border:1px solid white;" target="_blank" rel="noopener noreferrer" href="https://artopen.co/blog/how-to-make-a-logo-for-company-or-brand/">read more</a>
  </p>
</div>
<div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/blog.png?v=1651011033563" width="100%" /></div>
</div>
<br><br><br>
<div style="display: flex; justify-content: center">
<a target="_blank" rel="noopener noreferrer" href="https://facebook.com/artopenstudioreklamy/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/fb.png?v=1651013201802" width="30px" /></div></a>&nbsp;&nbsp;
<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/AReklamy/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/t.png?v=1651013201802" width="30px" /></div></a>&nbsp;&nbsp;
<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCR45VIn9iEmnQo7JU_FL2uQ/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/yt.png?v=1651013201802" width="30px" /></div></a>&nbsp;&nbsp;
<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/art-open-studio-reklamy/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/l.png?v=1651013202010" width="30px" /></div></a>&nbsp;&nbsp;
<a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/ArtOpenStudioReklamy/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/b.png?v=1651013202080" width="30px" /></div></a>&nbsp;&nbsp;
<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/artopen.pl/"><div><img src="https://cdn.glitch.global/505caa55-4dac-4177-8df2-396cd54200e4/i.png?v=1651013202263" width="30px" /></div></a>&nbsp;&nbsp;
</div>

<p style="text-align: center; font-size: 10px;margin-top:10px">
We hope you enjoy our content. If not
<a target="_blank" rel="noopener noreferrer" href="https://artopen.co/contact/">you can unsubscribe here.</a>
</p>

<hr style="border-width:5px;border-color: #23c495;width:70%" />
`



async function getStats() {
    try {
      const statsForDomain = await client.stats.getDomain(DOMAIN,{ 'event': ['accepted', 'delivered', 'failed'], 'duration': '1m'})
      console.log('statsForDomain', statsForDomain.stats);
      document.getElementById('stats').innerHTML = JSON.stringify(statsForDomain.stats[0].accepted.total) + ' / 5000' ;
      document.getElementById('statsfull').innerHTML = JSON.stringify(statsForDomain.stats[0]) ;

    } catch (error) {
      console.error(error);
    }
}



function destroyInfo(){
  console.log(global.localStorage.getItem('cipher'))
  global.localStorage.setItem('cipher','')
  console.log(global.localStorage.getItem('cipher'))
  navigate('/')
}


async function handleSubmit(){

  let messageData
  /*let fileinput = document.getElementById('fileinput');
  let file = fileinput.files[0];

  //disableEmptyInputs();

  if (file !== undefined){
      if (file.size < 1048576){
          let reader  = new FileReader();
          reader.readAsArrayBuffer(file)
          reader.onload = function(e) {
              const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
              let attachment = blob
              console.log(attachment)


              messageData = {
                from: document.getElementById('from').value,
                to: document.getElementById('to').value,
                cc: document.getElementById('cc').value,
                bcc: document.getElementById('bcc').value,
                subject: document.getElementById('subject').value,
                html: document.getElementById('value-holder').value,
                attachment
              };
              console.log(messageData)
              client.messages.create(DOMAIN, messageData)
               .then((res) => {
                 console.log(res);
               })
               .catch((err) => {
                 console.error(err);
               });

               alert('Emaile zostały wysłane, za 5 sekund zaktualizują się statystyki!')
               setTimeout(getStats,5000)
          };

      }
    } else { */

       messageData = {
          from: document.getElementById('from').value,
          to: document.getElementById('to').value,
          cc: document.getElementById('cc').value,
          bcc: document.getElementById('bcc').value,
          subject: document.getElementById('subject').value,
          html: document.getElementById('mirror').value,
        };
        console.log(messageData)
        client.messages.create(DOMAIN, messageData)
         .then((res) => {
           console.log(res);
           document.getElementById('send-msg').innerHTML = JSON.stringify(res);
           alert('Emails have been sent, statistics will update in 5 seconds!')
         })
         .catch((err) => {
           console.error(err);
           document.getElementById('send-msg').innerHTML = JSON.stringify(err);
         });
         setTimeout(getStats,5000)
      }







const EmailSender = () => {

  if (typeof window !== 'undefined') {
      console.log('we are running on the client')

  return(
  <>
  <Protect
  boxTitle="Art Open - this page is password protected."
  buttonLabel="Unblock"
  inputPlaceholder="enter password"
  sha512='5640b54b07066ac062884711b1a676fef4e9ba3231be4895254cd996946618406a6c85798b8424bdc3661b89b0bad9e2fcbfe27a7320fcb05975001f1f928028'>

  <Helmet
    htmlAttributes={{
      lang: `en-en`,
    }}
    title='Send mailing campaigns from the Art Open website'>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>
  {/*<div id="sprzatanie" style={{height:'85%',width:'100%',backgroundImage:'url(/img/akcja-sprzatanie-3.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',fontFamily:'Poppins, sans-serif !important'}}>
  </div>*/}

  <div style={{marginLeft:'10%',marginRight:'10%',padding:'5%'}}>
  <div id="action" style={{textAlign:'center',color:'#23C495',fontSize:'1.5em',width:'50%',marginLeft:'25%',marginTop:'50px',fontVariant:'all-petite-caps',letterSpacing: '0.3em'}}>
  <img src="/img/ArtOpen-green.svg" alt="Art Open advertising agency" style={{maxWidth:'300px'}} />
  <img src="/img/kontakt-center.svg" alt="Email sending" width="100px" />
  <h1 style={{fontWeight:'900',fontVariant:'all-small-caps',fontFamily:'system-ui',wordBreak:'break-word'}}>E-mail</h1>
  <h2 style={{fontWeight:'400',lineHeight:'3',marginTop:'-20px',fontFamily:'system-ui',fontVariant:'all-small-caps',fontSize:'.8em',wordBreak:'break-word'}}>Send email campaigns
  </h2>
  <br />
  <hr style={{backgroundColor:'#23C495',marginTop:'0px'}} />
  </div>





  <div style={{textAlign:'center',display:'block',justifyContent:'center',flexWrap:'wrap'}}>
  <br />
  <code style={{maxWidth:'100%', wordBreak:'break-all',marginTop:'30px'}} id="statsfull"></code>
  <br />
  <p style={{color:'#23C495', textAlign:'center',marginTop:'30px', wordBreak:'break-all'}} id="stats"></p>
  <br />
  <sub style={{marginTop:'10px'}}>Total data from sending emails - autoresponder + email campaigns.</sub>
  </div>
  <br />

  <div style={{textAlign:'center',paddingBottom:'50px'}}>
    <a className='button-green' onClick={getStats}> update statistics </a>
  </div>
  <br />

  <h2 className="title" style={{textAlign:'center'}}>Send an email campaign:</h2>


<div id="top" style={{backgroundColor:'#f5f5f5',padding:'10px',borderRadius:'10px'}}>
  <div className="field">
    <label className="label" htmlFor={'from'}>
      From<sup>*</sup>:
    </label>
    <div className="control">
      <input
        className="input"
        type={'text'}
        name={'from'}
        value={'Art Open <biuro@artopen.pl>'}
        id={'from'}
        required={true}
      />
    </div>
  </div>


  <div className="field">
    <label className="label" htmlFor={'subject'}>
      Subject<sup>*</sup>:
    </label>
    <div className="control">
      <input
        className="input"
        type={'text'}
        name={'subject'}
        id={'subject'}
        placeholder={'Promotions for 2022'}
        required={true}
      />
    </div>
  </div>

  <div className="field column">
    <label className="label" htmlFor={'to'}>
    List of recipients<sup>*</sup>:&nbsp;<span id="num"></span>
    </label>
    <div className="control">
      <textarea
        className="textarea"
        type={'text'}
        name={'odbiorcy'}
        placeholder={'foo@example.com, bar@example.com'}
        id={'to'}
        onChange={() => {
          let arr = document.getElementById('to').value.split(',')
          //console.log(arr.length)
          document.getElementById('num').innerText = arr.length-1
          sumEmails()
        }}
        required={true}
        rows = "3"
      ></textarea>
    </div>
    </div>

<div className="columns">
    <div className="field column">
      <label className="label" htmlFor={'cc'}>
        CC:&nbsp;<span id="num2"></span>
      </label>
      <div className="control">
        <input
          className="input"
          type={'text'}
          name={'cc'}
          placeholder={'kontakt@artopen.pl'}
          id={'cc'}
          onChange={() => {
            let arr = document.getElementById('cc').value.split(',')
            document.getElementById('num2').innerText = arr.length-1
            sumEmails()
          }}
          required={false}
        />
      </div>
    </div>

    <div className="field column">
      <label className="label" htmlFor={'bcc'}>
        BCC:&nbsp;<span id="num3"></span>
      </label>
      <div className="control">
        <input
          className="input"
          type={'text'}
          name={'bcc'}
          id={'bcc'}
          onChange={() => {
            let arr = document.getElementById('bcc').value.split(',')
            document.getElementById('num3').innerText = arr.length-1
            sumEmails()
          }}
          placeholder={'bok@artopen.pl'}
          required={false}
        />
      </div>
    </div>
</div>
</div>
<br />
<p style={{textAlign:'center'}} id="suma"></p>
<br />
<div className="columns">
<div className="column">
<br />
<hr />
<label className="label" htmlFor={'mirror'} style={{color:'#23C495'}}>Body element of the e-mail:</label>
  <textarea
    className="textarea"
    type={'text'}
    id="mirror"
    name={'body'}
    height="500px"
    onChange={() => {
      renderPrev()
    }}
    required={true}
    rows = "20"
  ></textarea>
  {/*}<CodeMirror
      value={htmlvalue}
      height="500px"
      theme={oneDark}
      id="mirror"
      maxWidth="500px"
      extensions={[javascript({ jsx: true })]}
      onChange={(value, viewUpdate) => {
        htmlvalue = value
        document.getElementById('value-holder').value = value;
        renderPrev()
      }}
    />*/}
<br />
<br />
<div style={{textAlign:'center',paddingBottom:'20px'}}>
  <a className='button-green' onClick={loadSample}> upload sample content </a> &nbsp;&nbsp;<button className='button-green' style={{backgroundColor:'white',marginTop:'55px'}} type="submit" onClick={handleSubmit}> Send an email campaign </button>
  <br /><br />
  <code style={{maxWidth:'100%', wordBreak:'break-all',marginTop:'30px'}} id="send-msg"></code>
</div>

<div style={{textAlign:'center',paddingBottom:'50px'}}>

  {/*<div className="field ">
  <div className='control'>
  <label className='label is-size-10'>Załącz plik:</label>
    <div className="file">
      <label className="file-label" style={{width:'100%',cursor:'pointer'}}>
        <input
          className="custom-file-input"
          type="file"
          name="plik"
          style={{width:'100%',cursor:'pointer'}}
          id ="fileinput"
        />
      </label>
    </div>
    <br />
    <p align="center" style={{backgroundColor:'#111111',color:'white',padding:'2px',fontSize:'12px'}}> The maximum file size is <b>1MB</b>. </p>
  </div>
  </div> */}
</div>
</div>
<div className="column">
<br />
<hr />
<label className="label" style={{color:'#23C495'}}>Email preview:</label>
<div style={{width:'100%',height:'max-content',border:'3px solid #23C495',padding:'10px',borderRadius:'10px'}} id="prev"></div>
  <br />
  <br />
</div>
</div>

<div style={{textAlign:'center',paddingBottom:'20px'}}>
  <a className='button-green' onClick={destroyInfo}> Close the mailing service</a>
</div>
<br />
</div>


  </Protect>
  </>
)} else {
    console.log('we are running on the server');
    return(
      <>
      <p>...</p>
      </>
    )
}
}

export default EmailSender

function renderPrev() {
    ReactDOM.render(renderHTML(document.getElementById('mirror').value), document.getElementById('prev'));
}
function loadSample(){
  document.getElementById('mirror').value = htmlvalue
  renderPrev()
}

function sumEmails(){
  //console.log(Number(document.getElementById('num').innerText),document.getElementById('num2').innerText,document.getElementById('num3').innerText)
  document.getElementById('suma').innerHTML = 'Total number of e-mails to be sent: '+(Number(document.getElementById('num').innerText) + Number(document.getElementById('num2').innerText) + Number(document.getElementById('num3').innerText))
}
